<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-2">Relatório de Diário</h1>
    <small class="text-red-700 text-sm font-semibold mb-3">O relatório diario pode sofrer com um delay tirando no dia, porém para filtrar para conversas em dias anteriores não tem alteração no fluxo </small>
    <form class="mb-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-2">
          <label for="de" class="mt-2 block text-sm font-medium">Acompanhar data</label>
          <input
            v-model="form.data"
            type="date"
            name="data"
            id="data"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>   
        <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
          <label for="dataDisparo" class="mt-2 block text-sm font-medium text-gray-700">
            Centro de custo</label
          >
          <v-select v-model="form.centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-3 xl:col-span-2">
          <button
            @click="list"
            type="button"
            class="md:mt-9 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Gerar relatório</span>
          </button>
        </div>
      </div>
    </form>
    <div class="py-3 px-2 border-2 w-96">
      <p class="block font-semibold">Total upload: <span class="text-yellow-600">{{ totalEnvios }}</span></p>
      <p class="block font-semibold">Total upload enviado: <span class="text-blue-700">{{ totalEnviados }}</span></p>
      <p class="block font-semibold">Total iniciado manualmente enviado: <span class="text-blue-700">{{ totalIniciadoManual }}</span></p>
      <p class="block font-semibold">Total Geral enviado: <span class="text-green-700">{{ total }}</span></p>
      <p class="block font-semibold">Total Respostas: <span class="text-gray-700">{{ respostas }} <span class="text-green-700">({{ porcentagem }}%)</span></span></p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [{ url: "/relatorios/relatorio-diario", value: "Relatório diário" }],
      route: "relatorio-envio",
      apilz: window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API,
      moment: moment,
      centroscusto: [],
      form: {
        centrocusto: null,
        data: moment().startOf("d").format("YYYY-MM-DD"),
      },
      total: 0,
      totalIniciadoManual: 0,
      totalEnvios: 0,
      totalEnviados: 0,
      respostas: 0,
      porcentagem: 0
    };
  },
  methods: {
    async list() {
      if(!this.form.data) {
        this.form.data = moment().startOf("d").format("YYYY-MM-DD");
      }

      const listReq = await this.$http.post(`/v1/chatV2/relatorio-diario`, this.form);
      this.total = listReq.data.total;
      this.totalIniciadoManual = listReq.data.totalIniciadoManual;
      this.totalEnvios = listReq.data.totalEnvios;
      this.totalEnviados = listReq.data.totalEnviados;
      this.respostas = listReq.data.respostas;
      this.porcentagem = listReq.data.porcentagem;
    },
  },
  async beforeMount() {
    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }
    
    this.list();
  },
};
</script>
